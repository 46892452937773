header {
  width: 100%;
  background-color: #343a40;
  color: white;
  padding: 10px 0;
  position: relative;
  top: 0;
  margin: 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .header-title {
  font-size: 1.5rem;
  margin: 0;
} */

.header-nav .btn {
  font-size: 1rem;
  padding: 0.5em 1em;
  border-radius: 4px;
  text-align: center;
}

.username-display {
  margin-right: 15px;
  font-size: 1rem;
  color: #ffffff;
}
