/* Center and style the username at the top */
.user-profile {
    background-color: rgb(235, 235, 235);
  }

.user-profile h2 {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 2rem;
    background-color: beige;
  }
  
  .palette-output-container {
    padding: 0 7%;
  }
  
  .palette-output {
    margin-bottom: 1.5rem;
    margin-right: -0.5rem;
    background-color: white;
   }
  