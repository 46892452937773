.color-outputs {
  margin-top: 20px;
}

.generated-colors {
  margin-top: 10px;
}

.add-color-btn {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  display: block;
  margin-top: 10px;
}

.explanation pre {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  line-height: 1.5;
}

.is-invalid input {
  border-color: red;
}

.is-invalid .form-control {
  border-color: red;
}

.valueInput label {
  margin-right: 5px;
}

.labelExample {
  color: grey;
}

.palette-output {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
  cursor: default;
}

.palette-output.hoverable:hover {
  background-color: rgba(80, 80, 80, 0.1);
}

.palette-output.selected {
  background-color: rgba(0, 0, 0, 0.2);
}

.palette-output {
  transition: background-color 0.3s ease;
}


.default-colors .color-output-batch {
  width: 1080px;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 270px;
}

.image-upload input {
  margin-bottom: 10px;
}

.image-container {
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  height: 300px;
}

.image-upload img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  align-self: flex-start;
}

.image-upload-box {
  display: flex;
}

.btn.btn-warning.clear-button {
  margin-bottom: 20px;
  margin-left: 0px;
}

.save-button-container {
  display: inline-block;
  float: right;
  margin-left: 10px;
}

.save-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.save-button i {
  color: #007bff;
  font-size: 1.2em;
}

.save-button:hover i {
  color: #0056b3;
}
