/* App.css */
body {
  padding-top: 0; /* Remove top padding */
  padding-bottom: 0; /* Remove bottom padding */
  margin: 0; /* Remove margin */
}

.content {
  min-height: calc(100vh - 120px); /* Adjust based on header and footer height */
  padding: 0px;
  /* background-color: beige; */
}

h1, h2 {
  font-family: 'Arial', sans-serif;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

/* Button styles for Landing Page */
.btn-lg {
  width: 100%;
  margin-bottom: 10px;
}

.d-grid {
  gap: 1rem;
}
