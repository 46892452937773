.color-output-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 160px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.color-box {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.color-info {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
}

.color-output-batch {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 540px;
}

.palette-label {
  font-weight: bold;
  margin-bottom: 5px;
}
